/* eslint-disable no-param-reassign */
import isFunction from 'lodash/isFunction';

import dom from '../../wrapper/DomWrapper';

const isIE = typeof document !== 'undefined' && document.documentMode;
const defaultConfig = {
  rootMargin: '0px',
  threshold: 0,
  enableAutoReload: false,
  load(element) {
    if (element.nodeName.toLowerCase() === 'picture') {
      let img = element.querySelector('img');
      let append = false;

      if (img === null) {
        img = document.createElement('img');
        append = true;
      }

      if (isIE && element.dataset.iesrc) {
        img.src = element.dataset.iesrc;
      }

      if (element.dataset.alt) {
        img.alt = element.dataset.alt;
      }

      if (append) {
        element.append(img);
      }
    }

    if (element.nodeName.toLowerCase() === 'video' && !element.dataset.src && element.children) {
      const childs = element.children;
      let childSrc;

      for (let i = 0; i <= childs.length - 1; i += 1) {
        childSrc = childs[i].dataset.src;

        if (childSrc) {
          childs[i].src = childSrc;
        }
      }

      element.load();
    }

    if (element.dataset.poster) {
      element.poster = element.dataset.poster;
    }

    if (element.dataset.src) {
      element.src = element.dataset.src;
    }

    if (element.dataset.srcset) {
      element.setAttribute('srcset', element.dataset.srcset);
    }

    let backgroundImageDelimiter = ',';

    if (element.dataset.backgroundDelimiter) {
      backgroundImageDelimiter = element.dataset.backgroundDelimiter;
    }

    if (element.dataset.backgroundImage) {
      element.style.backgroundImage = `url('${element.dataset.backgroundImage.split(backgroundImageDelimiter).join('\'),url(\'')}')`;
    } else if (element.dataset.backgroundImageSet) {
      const imageSetLinks = element.dataset.backgroundImageSet.split(backgroundImageDelimiter);
      let firstUrlLink = (imageSetLinks[0].slice(0, Math.max(0, imageSetLinks[0].indexOf(' '))) || imageSetLinks[0]);

      firstUrlLink = !firstUrlLink.includes('url(') ? `url(${firstUrlLink})` : firstUrlLink;

      if (imageSetLinks.length === 1) {
        element.style.backgroundImage = firstUrlLink;
      } else {
        element.setAttribute('style', `${element.getAttribute('style') || ''}background-image: ${firstUrlLink}; background-image: -webkit-image-set(${imageSetLinks}); background-image: image-set(${imageSetLinks})`);
      }
    }

    if (element.dataset.toggleClass) {
      element.classList.toggle(element.dataset.toggleClass);
    }
  },
};

const getLoadImageConfig = (onLoad) => ({
  load(element) {
    if (element.nodeName.toLowerCase() === 'picture') {
      let img = element.querySelector('img');
      let append = false;

      if (img === null) {
        img = document.createElement('img');
        append = true;
      }

      if (isIE && element.dataset.iesrc) {
        img.src = element.dataset.iesrc;
      }

      if (element.dataset.alt) {
        img.alt = element.dataset.alt;
      }

      if (append) {
        element.append(img);
      }

      if (isFunction(onLoad)) img.addEventListener('load', (e) => onLoad(e, element));
    }

    if (element.nodeName.toLowerCase() === 'video' && !element.dataset.src && element.children) {
      const childs = element.children;
      let childSrc;

      for (let i = 0; i <= childs.length - 1; i += 1) {
        childSrc = childs[i].dataset.src;

        if (childSrc) {
          childs[i].src = childSrc;
        }
      }

      element.load();
    }

    if (element.dataset.poster) {
      element.poster = element.dataset.poster;
    }

    if (element.dataset.src) {
      element.src = element.dataset.src;
    }

    if (element.dataset.srcset) {
      element.setAttribute('srcset', element.dataset.srcset);
    }

    let backgroundImageDelimiter = ',';

    if (element.dataset.backgroundDelimiter) {
      backgroundImageDelimiter = element.dataset.backgroundDelimiter;
    }

    if (element.dataset.backgroundImage) {
      element.style.backgroundImage = `url('${element.dataset.backgroundImage.split(backgroundImageDelimiter).join('\'),url(\'')}')`;
    } else if (element.dataset.backgroundImageSet) {
      const imageSetLinks = element.dataset.backgroundImageSet.split(backgroundImageDelimiter);
      let firstUrlLink = (imageSetLinks[0].slice(0, Math.max(0, imageSetLinks[0].indexOf(' '))) || imageSetLinks[0]);

      firstUrlLink = !firstUrlLink.includes('url(') ? `url(${firstUrlLink})` : firstUrlLink;

      if (imageSetLinks.length === 1) {
        element.style.backgroundImage = firstUrlLink;
      } else {
        element.setAttribute('style', `${element.getAttribute('style') || ''}background-image: ${firstUrlLink}; background-image: -webkit-image-set(${imageSetLinks}); background-image: image-set(${imageSetLinks})`);
      }
    }

    if (element.dataset.toggleClass) {
      element.classList.toggle(element.dataset.toggleClass);
    }
  },
});

const getLazyElements = () => {
  const lazyCollection = dom.getCollection('.lazy') || [];

  return [...lazyCollection].reduce((acc, elLazy) => {
    if (elLazy.dataset.loaded === 'true'
      || elLazy.closest('.slick-cloned')
      || dom.hasClass(elLazy, 'lightbox__img')
    ) return acc;

    return [...acc, elLazy];
  }, []);
};

const blockLazyElements = (elements = []) => {
  elements.forEach((elLazy) => {
    // eslint-disable-next-line no-param-reassign
    elLazy.dataset.loaded = 'true';
  });
};

const unBlockLazyElements = (elements = []) => {
  elements.forEach((elLazy) => {
    // eslint-disable-next-line no-param-reassign
    delete elLazy.dataset.loaded;
  });
};

export {
  defaultConfig,
  getLoadImageConfig,
  getLazyElements,
  blockLazyElements,
  unBlockLazyElements,
};
