import isEmpty from 'lodash/isEmpty';

import delay from '../../../../helper/delay';
import { CURRENT_PAGE_ANCHOR_CLASS_NAME } from '../../../constants';
import animateScroll from '../../../helpers/animateScroll';
import dom from '../../../wrapper/DomWrapper';
import lazyLoad from '../../LazyLoad';
import {
  blockLazyElements,
  getLazyElements,
  unBlockLazyElements,
} from '../../LazyLoad/utils';

// eslint-disable-next-line import/no-cycle
import {
  getHeaderOffset,
  getSectionId,
  getTopStickyOffset,
  setUrlHash,
} from './index';

export default (anchors) => {
  if (isEmpty(anchors)) return;

  anchors.forEach(async (anchor) => {
    const sectionId = getSectionId(anchor.hash);
    const elSection = dom.getElement(sectionId);

    if (!elSection) return;

    anchor.setAttribute('href', sectionId);
    anchor.classList.add(CURRENT_PAGE_ANCHOR_CLASS_NAME);

    await delay(0);

    dom.on(anchor, 'click', async (e) => {
      const endPosition = elSection.offsetTop;
      const headerOffset = getHeaderOffset();
      const topStickyOffset = getTopStickyOffset();
      const position = endPosition - headerOffset - topStickyOffset;
      const lazyElements = getLazyElements();

      e.preventDefault();
      blockLazyElements(lazyElements);
      animateScroll(position, {
        cb: async () => {
          await delay(300);
          unBlockLazyElements(lazyElements);
          lazyLoad();
        },
      });
      setUrlHash(sectionId);
    });
  });
};
