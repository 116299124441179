import delay from '../../../../helper/delay';
import animateScroll from '../../../helpers/animateScroll';
import dom from '../../../wrapper/DomWrapper';
import lazyLoad from '../../LazyLoad';
import {
  blockLazyElements,
  getLazyElements,
  unBlockLazyElements,
} from '../../LazyLoad/utils';

// eslint-disable-next-line import/no-cycle
import { getHeaderOffset } from './index';

export default async () => {
  const { hash } = dom.window.location;

  if (!hash || !hash.startsWith('#s-')) return;

  const elSection = dom.getElement(hash);

  if (!elSection) return;

  await delay(0);

  const endPosition = elSection.offsetTop;
  const headerOffset = getHeaderOffset();
  const position = endPosition - headerOffset;
  const lazyElements = getLazyElements();

  blockLazyElements(lazyElements);
  animateScroll(position, {
    scrollY: dom.window.pageYOffset,
    duration: 200,
    cb: async () => {
      await delay(300);
      unBlockLazyElements(lazyElements);
      lazyLoad();
    },
  });
};
